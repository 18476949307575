.calculate-section {
  height: auto;
  width: calc(99vw - 1px);
  margin: 0 !important;
}
.awb-container-page {
  height: calc(100vh - 95px);
  overflow-y: scroll;
}
.tabPanel {
  width: calc(99vw - 80px);
  overflow: auto;
}
@media only screen and (max-width: 770px) {
  .tabPanel {
    width: calc(99vw - 1px);
    overflow: auto;
  }
}

.react-datepicker-popper {
  z-index: 999 !important;
}

.active {
  > {
    background-color: white !important;
  }
}
