.sidebar-menu-container {
  width: 75px;
  -webkit-transition: width 0.5s;
  transition: width 0.5s;
  .sidebar-menu {
    align-items: flex-start;
    .text {
      display: none;
      color: white;
      transition: 1s;
    }
  }
  .active {
    background-color: #303f9f9c;
    width: 60%;
  }
}
.sidebar-menu-container:hover {
  width: 235px;
  -webkit-transition: width 1s;
  transition: width 1s;
  .sidebar-menu {
    align-items: flex-start;
    .text {
      display: flex;
      overflow: hidden;
      white-space: nowrap;
      word-wrap: break-word;
    }
  }
  .active {
    background-color: #303f9f9c;
    width: 90%;
  }
}

// .active{
//     background-color: #303f9f9c;
//     width: 90%;
// }
