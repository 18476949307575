.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}



.masked {
  position: relative;
  display: inline-block;
}

/* Masking effect applied via pseudo-elements */
.masked::after {
  content: '***'; /* Default masking content */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8); /* Semi-transparent overlay */
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0); /* Hide underlying text */
  font-weight: bold;
  pointer-events: none; /* Prevent interaction with the mask */
}
